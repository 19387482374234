<template>
  <div class="sidebar d-flex flex-column">
    <div class="d-flex">
      <div class="header-element first">
        <v-list-item-avatar><v-img :src="activityData.banner" /></v-list-item-avatar>
        <div>
          <div class="primary--text font-weight-bold mb-1" v-text="activityData.name" />
          <div class="text-body-2 grey--text" v-text="`${formatDate(activityData.date)} · ${formatTime(activityData.date)}`" />
        </div>
      </div>
      <v-divider vertical inset class="my-4" />
      <template v-if="projectData.features.seeViewersStreaming">
        <div class="header-element second">
          <div class="d-flex align-center black--text mb-1">
            <v-icon color="black" size="20" class="mr-1">
              person_outline
            </v-icon>
            <div class="header-count" v-text="data.stats.currentViewers" />
          </div>
          <div class="text-body-2 grey--text" v-text="$t('streaming.audience')" />
        </div>
        <v-divider vertical inset class="my-4" />
      </template>
      <div class="header-element third">
        <div class="d-flex align-center black--text mb-1">
          <v-icon color="black" size="20" class="mr-1">
            person_outline
          </v-icon>
          <div class="header-count" v-text="data.stats.currentHosts" />
        </div>
        <div class="text-body-2 grey--text" v-text="$t('streaming.hosts')" />
      </div>
    </div>
    <v-divider />
    <div v-chat-scroll="{ smooth: true }" class="comments-wrapper">
      <div class="comments-container text-body-2">
        <div v-for="comment in data.comments" :key="comment.createdAt._miliseconds">
          <span class="font-weight-bold" v-text="comment.userName" />
          <span class="mx-1" v-text="'·'" />
          <span class="grey--text" v-text="formatTime(comment.createdAt)" />
          <div v-text="comment.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
  name: 'StreamingSidebar',
  computed: {
    ...mapGetters({ projectData: 'project/data' }),
    ...mapGetters({ activityData: 'activity/data' }),
    ...mapGetters('streaming', ['data']),
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      return DateTime.fromSeconds(date?.seconds, {
        zone: this.projectData?.timezone,
      }).toLocaleString()
    },
    formatTime(date) {
      if (!date) return ''
      return DateTime.fromSeconds(date?.seconds, {
        zone: this.projectData?.timezone,
      }).toLocaleString(DateTime.TIME_24_SIMPLE)
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  flex: 0.5;
  height: 100%;
}

// Header
.header-element {
  display: flex;
  padding: 16px;

  &.first {
    flex: 1 1 250px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  &.second,
  &.third {
    flex: 1 1 100px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.header-count {
  font-weight: bold;
  line-height: 17px;
}

// Comments
.comments-wrapper {
  flex: 1;
  overflow-y: scroll;
}

.comments-container {
  display: grid;
  grid-gap: 20px;
  padding: 16px;
}
</style>
